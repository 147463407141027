/* width */
.row::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.row::-webkit-scrollbar-track {
    background: #D6D6D6;

}

/* Handle */
.row::-webkit-scrollbar-thumb {
    background: #AAAAAA;
    border-radius: 100px;
}

/* Handle on hover */
.row::-webkit-scrollbar-thumb:hover {
    background: grey;
}


#menu {
    display: none;
}

#menucollapse #downloadbtn {
    display: none;
}

#menucollapse #edit {
    display: none;
}

#menucollapse #delete {
    display: none;
}

#menucollapse #selector {
    display: none;
}


@media (max-width: 1205px) {

    #collapseNav #downloadbtn {
        display: none;
    }

    #menucollapse #downloadbtn {
        display: block;
    }

    #menu {
        display: block;
    }

}

@media (max-width: 1030px) {

    #collapseNav #edit {
        display: none;
    }

    #menucollapse #edit {
        display: block;
    }

}

@media (max-width: 901px) {

    #collapseNav #delete {
        display: none;
    }

    #menucollapse #delete {
        display: block;
    }

}

@media (max-width: 751px) {

    #collapseNav #selector {
        display: none;
    }

    #menucollapse #downloadbtn {
        display: block;
    }

}

.container {
    max-width: 100%;
}

.leaflet-heatmap-layer {
    opacity: .7;
}